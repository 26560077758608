.bg_1D1D1D{
    background-color: #1D1D1D;
}
.bg_363AC8{
    background-color: #363AC8;
}
.bg_000000{
    background-color: #000000;
}
.bg_FFFFFF{
    background-color: #FFFFFF;
}
.bg_1D1D1D{
    background-color: #1D1D1D;
}
.bg_DDDDDD{
    background-color: #DDDDDD;
}