@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");


* {
  font-family: -apple-system, BlinkMacSystemFont, 
  "Apple SD Gothic Neo", "Pretendard Variable", 
  Pretendard, Roboto, "Noto Sans KR", "Segoe UI", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.App {
  text-align: center;
}

.lodingBarBack{
  position: fixed;
  z-index: 99999999999999999998;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  left: 0px;
  top: 0px;
  display: none;

}
.lodingBar{
  position: fixed;
  z-index: 99999999999999999999;
  width: 150px;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  left: calc(50% - 75px);
  top: calc(50% - 50px);
}
.lodingBar > img{
  width: 100%;
}