.fnt_FFFFFF{
    color: #FFFFFF;
}
.fnt_1890FF{
    color: #1890FF;
}
.fnt_6B6B6B{
    color: #6B6B6B;
}
.fnt_9A9A9A{
    color: #9A9A9A;
}
.fnt_2A2E39{
    color: #2A2E39;
}