

/* App.css */
html, body {
    height: 100%;

}
div{
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box; 
 
}
input {
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box; 
 
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}
  
.body{

}
.withDrwTtl{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
}
.withDrwInp{
    width: 100%;
    height: 30px;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;   
 
}
.withDrwBtnRap{
    padding-left: 15px;
    padding-right: 15px;
}
.withDrwBtn{
    float: left;
    width: calc(50% - 5px);
    margin-top: 15px;
    height: 35px;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}

.withDrwBtnClose{
    float: left;
    margin-left: 10px;
    width: calc(50% - 5px);
    margin-top: 15px;
    height: 35px;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}

.withDrwInp >input{
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    text-align: right;
}
.withBal{
    width: 100%;
    text-align: right;
    font-size: 14px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    padding-left: 15px;
    padding-right: 15px;
}


.withDrwBack{
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 98;
}
.withDrw{
    display: none;
    position: fixed;
    z-index: 99;
    background-color: #FFFFFF;
    left: calc(50% - 150px);
    top: calc(50% - 90px);
    
    width: 300px;
    height: 180px;
    border-radius: 10px;
    
}


.HomeLst{
    width: 100%;

}

.widthMaxStyle{
    width: 100%;
    max-width: 880px;
    margin: 0 auto;
}
.homeTtl{
    margin-top: 150px;
    width: 100%;
    text-align: left;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
}
.homeTtlSub{
    font-size: 30px;
    font-weight:500;
}
.homeTtlTwo{
    font-size: 14px;
    width: 100%;
    text-align: left;
    margin-top: 5px;
}
.homeTtlValues{
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
}
.ltRtPadding{
    padding-left: 20px;
    padding-right: 20px;
}
.liRap{
    width: 100%;
    margin-top: 50px;
}
.liTdReset{
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}
.liTdTable{
    margin-top: 20px;
    border-spacing: 0px;
}
.liTdBodyImg{
    float: left;
    width: 35px;
}
.liTdBodyImg > img{
    width: 100%;
}
.liTdBodyTxt{
    float: left;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
}
.liTdBodyReset{
    padding-top: 20px;
    font-size: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid #DDDDDD;
}
.hdrRap{
    width: 100%;
    margin-top: 20px;
}
.resetFonTwo{
    cursor: pointer;
    text-decoration: underline;
    color: #121D57;
    font-weight: bold;
}
.btnStyles{
    font-weight: bold;
    width: 80px;
    height: 20px;
    cursor: pointer;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;
    border-radius: 2px;
    font-size: 14px;
    background-color: #121D57;
    margin-top: 10px;
}
.ttlMarginRet{
    margin-top: 40px;
}
.hdrLtLogo{
    cursor: pointer;
    float: left;
    width: 100px;
}
.hdrLtLogo > img{
    width: 100%;
}
.hdrRtRap{
    float: right;
    cursor: pointer;
}


.liveVer{
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    color: #DDDDDD;
}